{
  "PARTY_AUTHENTICATION_SMS_OTP_ALREADY_SENT": "An SMS has already been sent to this phone number. Please wait a few minutes.",
  "TRANSACTION_WITHDRAW_CODE_GENERATION_ERROR": "Withdrawal is not allowed for this transaction point",
  "common": {
    "today": "Today",
    "yesterday": "Yesterday",
    "goBack": "Go back",
    "languages": {
      "fr_FR": "Fran\u00e7ais (France)",
      "en_US": "English (USA)",
      "es_ES": "Espa\u00f1ol (Espa\u00f1a)"
    },
    "autocompletePicker": {
      "placeholder": "Select...",
      "searchPlaceholder": "Type at least 3 characters to search...",
      "noResults": "No result!"
    },
    "invalidPhoneNumber": "Phone number is invalid",
    "yes": "Yes",
    "no": "No",
    "cancel": "Done",
    "validate": "Validate"
  },
  "authScreen": {
    "welcome": "Welcome to your bank portal",
    "login": "Log in",
    "createAccount": "Create an account",
    "appstoreLink": "Appstore",
    "playstoreLink": "Playstore",
    "goToCompanyPortalQuestion": "Are you a company?",
    "goToCompanyPortalLink": "Business web portal",
    "goToPartnersPortalQuestion": "Are you a partner?",
    "goToPartnersPortalLink": "Partner web portal",
    "goToAgentPortalQuestion": "You are an agent?",
    "goToAgentPortalLink": "Agents web portal",
    "storesAvailable": "Also available on",
    "disconnectedDueToInactivity": "You have been disconnected due to inactivity",
    "authenticationFailure": {
      "title": "Authentication failure"
    }
  },
  "connectScreen": {
    "title": "Welcome to [emph]Skaleet[/emph]",
    "subtitle": "Please enter your phone number to log in",
    "lostPhoneButton": "Forgot phone number?",
    "connectButton": "Log in",
    "registerQuestion": "Don’t have an account?",
    "registerButton": "Register",
    "errorTitle": "An error occurred",
    "noAccountError": "We were unable to find an account for {phoneNumber}. You can create an account to log in.",
    "pincodeTitle": "Secret [emph]code[/emph]",
    "otpTitle": "Two-step [emph]verification[/emph]",
    "noCodeQuestion": "Code not received?",
    "contactSupportLink": "Please contact support.",
    "contactSupport": {
      "title": "You no longer [emph]have access to the phone[/emph] linked to your account ?",
      "message": "To change your details, please contact our support team who will tell you what to do.",
      "byPhone": "Contact us by phone",
      "byEmail": "Contact us by email",
      "done": "Done"
    }
  },
  "errorScreen": {
    "subTitle": "Too many requests",
    "title": "Connection attempt failed",
    "message": "We cannot process your connection request at this moment.\n\n Please try again later.",
    "button": "Return to log in page"
  },
  "onboardingStatusCard": {
    "title": "More information is required regarding your onboarding file",
    "subtitle": "Lorem ipsum dolor sit amet",
    "button": "Complete my file"
  },
  "onboardingDelegateErrorStatusCard": {
    "title": "Unable to access the registration form as a delegated user. The available features are limited. The account owner must complete their registration."
  },
  "onboardingScreen": {
    "progressLabel": "Step {step} of {count}: {label}",
    "initialStepTitle": "Welcome to [emph]M-Wallet[/emph]",
    "resumptionStepTitle": "Tell us more about [emph]you[/emph]",
    "successIntroduction": "Congratulations",
    "successTitle": "Your onboarding file has been validated",
    "successButton": "Let's start",
    "initiationSuccessTitle": "Your enrollment has been successfully initiated.",
    "initiationSuccessMsg": "A secret code has been sent to {phone}",
    "initiationSuccessButton": "Let's connect",
    "getStartedButton": "Get started",
    "cancelButton": "Cancel",
    "back": "Back",
    "next": "Next",
    "save": "Save",
    "submit": "Submit",
    "taxedNumberError": {
      "undefined": "Error: Please enter a valid phone number.",
      "PREMIUM_RATE": "Error: Premium rate numbers cannot be used for account registration. Please provide either a mobile or fixed-line number.",
      "TOLL_FREE": "Error: Toll-free numbers are not supported for account verification. Please use a standard mobile or stationary phone number.",
      "SHARED_COST": "Error: Numbers with shared cost billing are not permitted for account creation. Please enter a mobile or fixed-line number.",
      "VOIP": "Error: Internet-based phone numbers are not eligible for registration. Please use a standard mobile or fixed-line number.",
      "PERSONAL_NUMBER": "Error: Personal numbers connected via satellite are not accepted. Please use a mobile or stationary phone number.",
      "PAGER": "Error: Pager devices are not valid for account creation. Please provide an accepted mobile or fixed-line number.",
      "UAN": "Error: Universal Access Numbers (UAN) cannot be used for registration. Please enter a valid mobile or fixed-line number.",
      "VOICEMAIL": "Error: Numbers dedicated to voicemail services cannot be used. Please enter a mobile or fixed-line number that can directly receive calls."
    },
    "formLabels": {
      "selectDistributorDuringOnboarding": "Onboarding distributor code",
      "selectDistributorDuringOnboardingHelperText": "Please enter the ID provided by your card referent.",
      "firstName": "First name",
      "lastName": "Last name",
      "phoneNumber": "Phone number",
      "email": "Email"
    }
  },
  "registerScreen": {
    "progressLabel": "Step {step} of {count}: {label}",
    "firstStepTitle": "Welcome!",
    "firstStepSubtitle": "Please enter your phone number to register",
    "connectQuestion": "Already have an account?",
    "connectButton": "Log in",
    "phoneNumberLabel": "Phone number",
    "back": "Back",
    "next": "Next",
    "finish": "Finish",
    "otpTitle": "Phone checking",
    "otpLabel": "Please enter the code sent to your mobile phone",
    "nbPicturesForDocument": {
      "one": "at least one picture",
      "others": "at least {count} pictures"
    },
    "pictureInputFilled": "One file",
    "pictureInputEmpty": "Choose or drop a picture",
    "addPictureButton": "Add a page",
    "eulaTitle": "End-user licence agreement",
    "eulaAcceptLabel": "I accept end-user licence agreement",
    "eulaFirstDescription": "Please accept the terms of use in order to create your account.",
    "eulaSecondDescription": "You will find them by clicking on the following link:",
    "eulaLink": "End-user licence agreement",
    "createMyAccountButton": "Create my account",
    "finishTitleLabel": "Congratulations !",
    "finishLabel": "Your account has been successfully created.",
    "pincodeLabel": "Your secret code",
    "pincodeStepTitle": "Your secret [emph]code.[/emph]",
    "message": "You must choose a four-digit secret code for your account. Do not communicate it to anyone, it is strictly confidential.",
    "doNotForgetWarning": "Be careful to keep it well: you will need it to authenticate yourself and validate all your transactions. ",
    "successSnackbar": "Your code has been confirmed successfully",
    "quitConfirmMessage": "You are about to exit registration. All data filled in will be lost. Are you sure you want to leave the page ?",
    "cancel": "Cancel",
    "newCodeLabel": "Enter the new code",
    "newCodeConfirmationLabel": "Confirm the new code",
    "newCodesDontMatchError": "New codes don't match",
    "noEulaTitle": "Account creation confirmation",
    "noEulaFirstDescription": "You're almost done!",
    "noEulaSecondDescription": "By clicking the following link, your account creation will be effective. Let's go?",
    "privacyTitle": "Privacy policy",
    "privacyFirstDescription": "Please accept the privacy policy in order to create your account.",
    "privacySecondDescription": "You will find them by clicking on the following link:",
    "privacyLink": "Privacy policy",
    "privacyAcceptLabel": "I accept the privacy policy",
    "noPrivacyTitle": "Account creation confirmation",
    "noPrivacyFirstDescription": "You're almost done!",
    "noPrivacySecondDescription": "By clicking the following link, your account creation will be effective. Let's go?"
  },
  "accountOwner": {
    "male": "Mr. {name}",
    "female": "Ms. {name}",
    "other": "{name}",
    "none": "{name}"
  },
  "connectedUserGreetings": {
    "withName": "Hello,\n[emph]{name}[/emph]!",
    "withoutName": "Hello!"
  },
  "clientStatus": {
    "clientBlockedMessage": "Your account is blocked.",
    "clientBlockedLink": "More information...",
    "clientPendingMessage": "We are processing your account validation."
  },
  "navigationMenu": {
    "accountLink": "Accounts",
    "transferLink": "Transfers",
    "payBillLink": "Services",
    "cardsLink": "Cards",
    "savingsLink": "Savings",
    "loansLink": "Loans",
    "contactLink": "Help and support",
    "settingsLink": "See my profile",
    "disconnectButton": "Logout"
  },
  "transfertsSubmenu": {
    "allTransfer": "Make a transfer",
    "externalAccount": "External account",
    "recipients": "Recipients",
    "cashTransfer": "Pending transfers",
    "upcomingTransfers": "Upcoming transfers",
    "recharge": "Recharge my account",
    "transactionCode": "Transaction code",
    "simpleTransfer": "With phone number"
  },
  "savingsSubmenu": {
    "mySavingsAccount": "My saving accounts",
    "deposits": "Transfers and deposits",
    "createSavingsAccount": "Create a savings account"
  },
  "simpleTransfer": {
    "newTransfer": "New transfer",
    "title": "With phone number",
    "searchRecipient": "Search a recipient",
    "invalidPhoneNumber": "The provided phone number is not recognized"
  },
  "accountPendingVerification": {
    "title": "Account verification",
    "subtitle": "Your account opening request is under verification. Please retry later once it has been reviewed by the back-office.",
    "retryButton": "Retry"
  },
  "accountsList": {
    "title": "My accounts.",
    "loadAccountsError": "An error occurred while loading accounts.",
    "loadAccountsRetry": "Retry",
    "showAllAccountsLink": "Show all accounts",
    "noAccountAvailable": "No account available"
  },
  "recipient": {
    "title": "Make a transfer",
    "subtitle": "Who do you want to transfer your money to?",
    "section": "My recipients",
    "noRecipientsAvailable": "No recipient available",
    "noRecipientsMessage": "Add recipients to transfer money.",
    "addRecipientButton": "Add a recipient",
    "transferTo": "Transfer to {name}",
    "searchInputPlaceholder": "Search",
    "selector": {
      "beneficiaries": "Recipients",
      "externalAccounts": "External account"
    },
    "deletePrompt": {
      "title": "Delete",
      "message": "Do you really want to delete the recipient {name} ?",
      "recipient": "a recipient",
      "externalAccountTitle": "external account",
      "externalAccountMessage": "Please confirm that you want to delete your external account",
      "cancelButton": "Cancel",
      "confirmButton": "Confirm"
    },
    "externalAccounts": {
      "title": "My external accounts",
      "addButton": "Add an external account",
      "noneAvailableTitle": "Currently no external account",
      "noneAvailableMessage": "Add an external bank account to transfer money.",
      "pendingExternalAccountMessage": "Your external account is being validated."
    }
  },
  "addRecipient": {
    "selector": {
      "iban": "IBAN",
      "phone": "Phone",
      "account": "Account num / BIC"
    },
    "unauthorized": "You are not authorized to access this feature",
    "title": "Add",
    "subtitle": "a recipient",
    "externalAccountSubtitle": "an external account",
    "nameFieldPlaceholder": "Name",
    "phoneFieldPlaceholder": "Phone number",
    "ibanFieldPlaceholder": "IBAN",
    "accountFieldPlaceholder": "Account number",
    "bicFieldPlaceholder": "BIC",
    "bankFieldPlaceholder": "Bank/BIC",
    "createButton": "Add",
    "otpAdditionalText": "Add recipient {name}",
    "otpAdditionalTextWithBank": "Add recipient {name} from bank {bankName}",
    "addConfirmationMessage": "The recipient {name} was added successfully",
    "searchBank": {
      "title": "Bank/BIC",
      "bankPlaceholder": "Type at least {num} letters to search",
      "firstSearchMessage": "Make a first search to get some results",
      "noBankMessage": "No matching bank found",
      "errorMessage": "An error occurred while loading the list of banks.",
      "retryButton": "Retry"
    }
  },
  "editRecipient": {
    "title": "Edit",
    "subtitle": "a recipient",
    "externalAccountSubtitle": "external account for",
    "saveButton": "Save"
  },
  "account": {
    "accountId": "N°{id}",
    "accountType": {
      "current": "Current account",
      "savings": "Savings account",
      "dedicated": "Dedicated account",
      "moneyPot": "Money Pot",
      "technical": "Technical account"
    },
    "detail": "Details"
  },
  "accountStatus": {
    "opened": "Opened",
    "closed": "Closed",
    "blocked": "Blocked",
    "debitBlocked": "Debit forbidden",
    "creditBlocked": "Credit forbidden"
  },
  "accountTransactions": {
    "title": "My transactions.",
    "emptyTransactionList": "There are no transactions in this period.",
    "loadTransactionsError": "An error occurred while loading transactions.",
    "loadMoreTransactionsError": "An error occurred while loading transactions.",
    "loadMoreTransactionsRetry": "Retry",
    "unjustifiedTransactions": {
      "title": {
        "one": "You have {count} transaction waiting for substantiation",
        "other": "You have {count} transactions waiting for substantiation",
        "more": "You have {count} or more transactions waiting for substantiation"
      },
      "description": "Some of your transactions are waiting for substantiation documents."
    },
    "unjustifiedTransactions2": {
      "title": {
        "one": "You have {count} transaction waiting for substantiation",
        "other": "You have {count} transactions waiting for substantiation",
        "more": "You have {count} or more transactions waiting for substantiation"
      },
      "description": "Some of your transactions are waiting for substantiation documents."
    },
    "unqualifiedTransactions": {
      "title": {
        "one": "You have {count} transaction waiting for qualification",
        "other": "You have {count} transactions waiting for qualification",
        "more": "You have {count} or more transactions waiting for qualification"
      },
      "description": "Some of your transactions are waiting for qualification documents."
    },
    "authorizationHolds": {
      "title": {
        "one": "You have {count} pending transaction",
        "other": "You have {count} pending transactions",
        "more": "You have {count} pending transactions"
      },
      "description": "The money has been debited from the account but is not yet received by the beneficiary."
    },
    "expenseUnjustified": "Unjustified",
    "previousPeriodButtonLabel": "Previous period",
    "nextPeriodButtonLabel": "Next period"
  },
  "authorizationHolds": {
    "cashoutLabel": "Cashout",
    "paymentLabel": "Payment",
    "externalToAccountLabel": "External to account",
    "accountToExternalLabel": "Account to external",
    "externalFromAccountLabel": "External from account",
    "accountRechargeLabel": "Account recharge",
    "onUsCreditTransferLabel": "On-us credit transfer",
    "managementFeesLabel": "Management fees"
  },
  "accountingTransactionDetailsModal": {
    "detailsTitle": "Details",
    "transferLabel": "Transfer",
    "description": "Description: {description}",
    "transaction": "Transaction {id}",
    "reference": "Reference: {id}",
    "feeLabel": "Fee",
    "commissionLabel": "Commission",
    "taxLabel": "Tax",
    "discountLabel": "Discount",
    "interestLabel": "Interests",
    "amountLabel": "Total",
    "balanceLabel": "Balance after transaction",
    "additionalInformationTitle": "Additional information",
    "geolocationTitle": "Localization",
    "externalIdLabel": "Partner transaction reference: {externalId}",
    "justifyButtonLabel": "Justify",
    "refundButtonLabel": "Request a refund",
    "verifyJustificationsButtonLabel": "Verify justifications",
    "justificationFollowUpTitle": "Justification follow-up",
    "documentsSubmitted": "Supporting documents submitted",
    "validationInProgress": "Waiting for validation"
  },
  "authorizationHoldsDetailsModal": {
    "pending": "Pending",
    "detailsTitle": "Details",
    "transferLabel": "Transfer",
    "reference": "Transaction {id}",
    "amountLabel": "Total"
  },
  "allAccountsScreen": {
    "goBackClassicViewButton": "Go back to the classic view",
    "noAccountAvailable": "No account available"
  },
  "recipientDetail": {
    "information": "Informations.",
    "accountDetail": "RIB/Account information.",
    "transferMoneyButton": "Transfer money",
    "dedicatedTransferButton": "Dedicated transfer"
  },
  "amountSelection": {
    "amountLabel": "Amount",
    "currencyLabel": "Currency :",
    "descriptionLabel": "Description :",
    "descriptionButton": "Add a description",
    "descriptionPlaceholder": "Write something…",
    "simpleTransferNameLabel": "Name :",
    "simpleTransferNamePlaceholder": "Write a name",
    "simpleTransferPhoneLabel": "Phone :",
    "simpleTransferPhonePlaceholder": "Write a phone number",
    "sendButton": "Send",
    "since": "Since",
    "to": "To",
    "choose_source_account": "Choose a source account",
    "choose_destination_account": "Choose a destination account",
    "my_accounts": "My accounts",
    "my_external_account": "External account",
    "my_recipients": "My recipients",
    "network": "Interbank network",
    "load_more": "Load more",
    "convertedAmountLabel": "Converted amount",
    "foreignCurrencyPlaceholder": "Choose a currency",
    "creditorAddress": {
      "addressLabel": "Address",
      "countryLabel": "Country",
      "buildingNumber": "N°",
      "streetName": "Street",
      "postCode": "Post code",
      "townName": "Town",
      "country": "Country"
    }
  },
  "acceptorSelection": {
    "title": "Selection of a trader",
    "selectionButton": "Select"
  },
  "dedicatedTransferAmount": {
    "title": "Gift amount",
    "subtitle": "Transaction point: {code}"
  },
  "dedicatedTransferSummary": {
    "recepient": "Recipient",
    "account": "Account debited",
    "amount": "Amount",
    "transactionPoint": "Transaction point",
    "expirationDate": "Date of expiry",
    "done": "Done"
  },
  "pincodeConfirmation": {
    "label": "Tap your secret code to continue",
    "networkError": "Please check your internet connection before continuing",
    "loadKeyboardError": "An error occurred while loading keyboard.",
    "loadKeyboardRetry": "Reload keyboard",
    "forgetPincode": "I forgot my secret code"
  },
  "transferSummary": {
    "accountTransferMode": "To account",
    "cashTransferMode": "To cash",
    "summaryTitle": "Success",
    "accountSummarySubtitle": "Details of the transfer",
    "cashSummarySubtitle": "You will receive message containing a code to give to {recipient} or you can consult your pending transfers to get the code.",
    "summaryMiddleStep": "Transfer",
    "fees": "Fees: {amount}",
    "commission": "Commission: {amount}",
    "discount": "Discount: {amount}",
    "tax": "Tax: {amount}",
    "you": "You",
    "done": "Done",
    "customerInstructionSuccessMessage": "Your transfer N°{id} to {recipient} has been successfully completed.",
    "recurringTransferSuccessMessage": "The recurring transfer N°{id} to {recipient} has been successfully created."
  },
  "billFormScreen": {
    "nextButton": "Next",
    "textInputPlaceholder": "Write something…",
    "saveReferencePromptTitle": "Save {name}",
    "replaceReferencePromptTitle": "Replace {name}",
    "saveReferencePromptMessage": "Do you want to save the {name} for this partner?",
    "replaceReferencePromptMessage": "Do you want to replace the {name} for this partner?",
    "saveReferencePromptCancel": "Cancel",
    "saveReferencePromptConfirm": "Save",
    "replaceReferencePromptConfirm": "Replace",
    "saveReferenceErrorMessage": "The {name} could not be saved",
    "docInputFilled": "One file",
    "docInputEmpty": "Add or drop a picture here",
    "confirmationMessage": "Click below to confirm your payment of [b]{amount} [/b] to [b]{issuer}[/b]",
    "confirmButton": "Confirm"
  },
  "billConfirmationScreen": {
    "fees": "Fees : [b]{amount}[/b]",
    "commission": "Commission : [b]{amount}[/b]",
    "discount": "Discount : [b]{amount}[/b]",
    "tax": "Tax : [b]{amount}[/b]"
  },
  "formError": {
    "invalidIBAN": "IBAN is not valid",
    "invalidFileSize": "Your file exceeds the maximum accepted size ({size})",
    "invalidFileFormat": "The format of your file is not supported. Formats accepted: {formats}"
  },
  "billIssuersScreen": {
    "title": "Pay a bill.",
    "references": "My references",
    "searchInputPlaceholder": "Search",
    "emptyListLabel": "No partner available",
    "loadBillIssuersError": "An error occurred while loading the partners list."
  },
  "billReferencesScreen": {
    "title": "My references",
    "services": "Go back to my services",
    "subtitle": "Saved data for your bill payments",
    "emptyRecipientList": "No registered reference",
    "deletePromptTitle": "Delete reference",
    "deletePromptMessage": "Please confirm that you want to delete reference {name}",
    "deletePromptCancel": "Cancel",
    "deletePromptConfirm": "Confirm",
    "deleteConfirmationMessage": "The reference {name} was removed successfully",
    "deleteErrorMessage": "Deleting the reference {name} failed",
    "loadBillReferencesError": "An error occurred while loading references.",
    "lastUpdateDateTime": "Last update : {date} at {time}"
  },
  "otp": {
    "explanation": "Enter the six-digit code we sent you at:",
    "sendBackCodeButton": "Send back code",
    "cancelOtpButton": "Cancel",
    "confirmOtpButton": "Confirm",
    "goToTransferFormButton": "Go to transfer form",
    "backToRecipients": "Back",
    "otpBlockedErrorMessage": "You made 3 invalid tries. Please restart the operation from the beginning."
  },
  "accountDetails": {
    "availableBalanceTitle": "Available balance",
    "balanceTitle": "Account balance",
    "balanceMessage": "The account balance does not include pending transactions.",
    "accountStatus": {
      "blocked": "This account is blocked. For more information please contact our support.",
      "debitBlocked": "Debits are currently forbidden on this account. For more information please contact our support.",
      "creditBlocked": "Credits are currently forbidden on this account. For more information please contact our support."
    },
    "accountName": "Account name",
    "accountIdTitle": "Account number",
    "ibanTitle": "IBAN",
    "bicTitle": "BIC",
    "accessStatements": "Account statements",
    "shareRIBLabel": "Share account info",
    "shareRIBMessage": "{firstName} {lastName} {postName}\nIBAN: {iban}\nBIC: {bic}",
    "dedicatedMessage": "You can spend this amount at:",
    "clipboard": "{name} copied",
    "downloadBankDetails": "Bank details",
    "accountStartDate": "Account opening date",
    "maximumBoundaries": "Maximum amount",
    "initialAmountLabel": "Initial amount",
    "endDateLabel": "End date",
    "durationLabel": "Duration",
    "monthsLabels": {
      "DAILY": "days",
      "WEEKLY": "weeks",
      "MONTHLY": "months",
      "QUARTERLY": "quarters",
      "SEMESTER": "semesters",
      "ANNUALLY": "years"
    },
    "rateValueLabel": "Applied rate",
    "indexCodeLabel": "Index",
    "rateStartDateLabel": "Rate start date",
    "accruedInterestsLabel": "Accrued interests",
    "startDateLabel": "Earned since",
    "forecastInterestsLabel": "Forecast interests",
    "initialAERLabel": "AER",
    "success": "Saved"
  },
  "accountsStatements": {
    "title": "My account statements",
    "subtitle": "All the statements",
    "accountLabel": "Account n°{id}",
    "accounts": "Accounts",
    "statements": "Available account statements",
    "emptyStatementList": "No account statement available",
    "cancel": "Cancel",
    "loadStatementsError": "An error occurred while loading account statements.",
    "generateButton": "Generate"
  },
  "feesStatements": {
    "title": "My fees statements",
    "subtitle": "All the statements",
    "accountLabel": "Account n°{id}",
    "accounts": "Accounts",
    "statements": "Available fees statements",
    "emptyStatementList": "No fee statement available",
    "cancel": "Cancel",
    "loadStatementsError": "An error occurred while loading fees statements."
  },
  "cardsList": {
    "title": "My cards.",
    "loadCardsError": "An error occurred while loading cards.",
    "loadCardsRetry": "Retry",
    "showAllCardsLink": "Show all cards",
    "orderCard": "Order a new card",
    "refabricateButton": "Replace card",
    "infos": {
      "title": "Additional infos"
    },
    "details": {
      "title": "Card details",
      "holder": "Cardholder name",
      "number": "Number",
      "date": "Expiration date"
    }
  },
  "allCardsScreen": {
    "goBackClassicViewButton": "Go back to the classic view",
    "noCardAvailable": "No card available"
  },
  "card": {
    "virtual": "Virtual",
    "title": "CARD",
    "status": {
      "all": "All",
      "active": "Active",
      "pending": "Pending",
      "new": "New",
      "expired": "Expired",
      "blocked": "Blocked",
      "opposed": "Opposed",
      "removed": "Removed",
      "cancelled": "Cancelled"
    },
    "feature": {
      "nfc": "NFC",
      "cashOperation": "Cash operation",
      "foreignPayment": "Foreign payment",
      "onlinePayment": "Online payment",
      "eurWithdrawal": "Euro zone withdrawal",
      "foreignWithdrawal": "Foreign withdrawal",
      "paymentAuthorized": "Authorized payment",
      "eurPayment": "Euro zone payment",
      "foreignAll": "Foreign payment and withdrawal",
      "foreignOnlinePayment": "Foreign online payment"
    },
    "pendingOperation": {
      "refabrication": "The card is currently being remade",
      "codeReissuing": "Your PIN code change is currently processing",
      "opposition": "The card is currently being opposed",
      "blocking": "The card is currently being blocked",
      "unblocking": "The card is currently being unblocked",
      "activation": "The card is currently being activated",
      "creation": "The card is currently being created",
      "limitsUpdate": "The limits for your card are currently updating",
      "featuresUpdate": "The \"{feature}\" feature for your card is currently processing",
      "unknownFeatureUpdate": "A feature for your card is currently processing"
    }
  },
  "cardPincode": {
    "importantMessage": "Be careful to keep this code:\nYou will need it to validate transactions with your card.",
    "previous": "Change",
    "next": "Next",
    "errorCodesMismatch": "Pincodes do not match."
  },
  "cardOptions": {
    "title": "Payment settings",
    "outstandings": {
      "navigateButton": "My limits",
      "title": "My limits",
      "subtitle": "See your card limits",
      "paymentHeader": "Payment limits",
      "cashoutHeader": "Cashout limits",
      "slidingPeriod": "rolling period of {num} {unit}",
      "fixedPeriod": "from {startDate} to {endDate}",
      "used": "Used",
      "available": "Available",
      "maxAmount": "Max amount",
      "maxOperations": "Max operations",
      "selector": {
        "payments": "Payments",
        "cashouts": "Cashouts"
      },
      "noOutstandingLabel": "No limits set",
      "errorMessage": "An error occurred while loading limits.",
      "retryButton": "Retry",
      "durationUnit": {
        "hour": "hour",
        "hours": "hours",
        "day": "day",
        "days": "days",
        "week": "week",
        "weeks": "weeks",
        "month": "month",
        "months": "months",
        "year": "year",
        "years": "years"
      },
      "updateButtonLabel": "Update my limit",
      "modal": {
        "title": "Update my limit",
        "inputLabel": "New limit",
        "inputLabelUnit": {
          "day": "(daily)",
          "week": "(weekly)",
          "month": "(monthly)",
          "year": "(yearly)",
          "all": "(global)"
        },
        "maximumLabel": "Maximum",
        "submitButtonLabel": "Submit",
        "validationMsg": "Your request has been sent"
      }
    },
    "refabricate": {
      "modal": {
        "title": "Confirm",
        "message": "Please confirm that you want to replace this card",
        "cardNumber": "{cardNumber} ",
        "submitButtonLabel": "Confirm"
      },
      "motiveLabel": "Please select a motive",
      "motivesOptions": {
        "DAMAGED": "Damaged card",
        "STOLEN": "Stolen card",
        "LOST": "Lost card",
        "ABUSE": "Card abuse"
      }
    },
    "oppositionCard": {
      "opposeButton": "Oppose this card",
      "title": "I want to oppose this card",
      "reasonLost": "Lost card",
      "reasonStolen": "Stolen card",
      "reasonNotDistributed": "Not distributed",
      "opposeConfirmButton": "Confirm",
      "opposeCancelButton": "Cancel",
      "opposePromptTitle": "Oppose this card",
      "opposePromptMessage": "You can not revert this action. Are you sure you want to oppose ?"
    },
    "deactivateCard": {
      "deactivateButton": "Deactivate this card",
      "title": "I want to deactivate this card",
      "deactivateConfirmButton": "Confirm",
      "deactivateCancelButton": "Cancel",
      "deactivatePromptTitle": "Deactivate this card",
      "deactivatePromptMessage": "You can not revert this action. Are you sure you want to oppose ?"
    },
    "updateCardPincode": {
      "updateButton": "Change card PIN code",
      "title": "New PIN code",
      "definePincodeMessage": "Choose another four-digit PIN code for your card. Above all, do not communicate it to anyone, it is strictly confidential.",
      "confirmPincodeMessage": "Please confirm your card pincode to continue.",
      "successTitle": "Congratulations !",
      "successSubtitle": "Your card PIN code has been updated !",
      "failureTitle": "Error",
      "failureSubtitle": "Please excuse us.",
      "failureDescription": "An error occured while updating the PIN code for your card. Please try again later or contact our customer service.",
      "confirmationPopup": {
        "message": "If you quit while modifying the card PIN code it won't be updated.\nQuit editing ?"
      }
    },
    "activationCard": {
      "title": "Activate my card",
      "activateButton": "Activate",
      "defaultActivationMessage": "Enter the 9-digit identifier on the back of your card.",
      "identifier": "The 9-digit identifier",
      "next": "Next",
      "confirm": "Activate",
      "inputError": "Input error, please try again.",
      "stepPincode": {
        "title": "PIN code",
        "definePincodeMessage": "You must now choose a four-digit PIN code for your card. Above all, do not communicate it to anyone, it is strictly confidential.",
        "confirmPincodeMessage": "Please confirm your card pincode to continue."
      }
    },
    "switch": {
      "lockCard": "Temporary lock",
      "unlockCard": "Unlock",
      "showDetails": "Voir le détail",
      "hideDetails": "Masquer le détail"
    },
    "featuresSwitchesOverlay": {
      "cardLocked": "Card blocked"
    },
    "cardOpposedMessage": "You have opposed this card. You can not access to the settings of this card."
  },
  "accountBlockedScreen": {
    "title": "Blocked account",
    "multipleAttemptBlockedReason": {
      "subtitle": "Three wrong secret code attempts",
      "message": "Unfortunately, your account has been blocked due to three incorrect secret code attempts. Please contact our support {contacts}to reset your secret code."
    },
    "defaultBlockedReason": {
      "message": "Unfortunately, your account has been blocked. Please contact our support {contacts}to reset your secret code."
    },
    "contactsPhoneAndEmail": "to {contactPhone} or by email to {contactEmail} ",
    "contactsPhone": "at {contactPhone} ",
    "contactsEmail": "or by email to {contactEmail} ",
    "button": "Ok I understood"
  },
  "bankContactScreen": {
    "title": "My bank.",
    "question": "Need help?",
    "addressContact": "Find us",
    "phoneContact": "Call us",
    "urlContact": "Learn more",
    "emailContact": "Write us"
  },
  "pincodeChangeFlow": {
    "currentCodeLabel": "Enter your current code",
    "newCodeLabel": "Enter the new code",
    "newCodeConfirmationLabel": "Confirm the new code",
    "newCodesDontMatchError": "New codes don't match",
    "success": "Your code has been updated successfully"
  },
  "pincodeChangeScreen": {
    "title": "Code change",
    "subtitle": "Change your secret code for more security",
    "message": "You are required to change your secret code in order to continue",
    "messageAfterAuthentication": "You will be required to change your secret code in order to initiate new transactions",
    "changePincodeButton": "Change my secret code",
    "laterButton": "Later",
    "done": "Done"
  },
  "clientInformation": {
    "title": "My profile",
    "birthDateTitle": "Birth date",
    "birthPlaceTitle": "Birth place",
    "addressTitle": "Address",
    "phoneTitle": "Phone",
    "emailTitle": "Mail",
    "emailSuccess": "You have received a confirmation email"
  },
  "cashTransferScreen": {
    "title": "My pending transfers",
    "subtitle": "Cancel or retrieve your cash transfers",
    "receivedSelector": "Received",
    "sentSelector": "Sent",
    "recipient": "Sent to : ",
    "sender": "Sent by : ",
    "emptyReceivedList": "No transfer received",
    "emptySentList": "No transfer send",
    "receivedTransferDefaultTitle": "Transfer received",
    "sentTransferDefaultTitle": "Transfer sent",
    "codeLabel": "Code : {code}",
    "cancelCashTransferButton": "Cancel transfer",
    "retrieveCashTransferButton": "Send to my account",
    "loadCashTransfersError": "An error occurred while loading cash transfers.",
    "loadMoreCashTransfersError": "An error occurred while loading cash transfers.",
    "loadMoreCashTransfersRetry": "Retry",
    "nextButton": "Next",
    "cashCodeTitle": "Enter the code sent by the transfer sender",
    "invalidCode": "The code entered is invalid",
    "summaryTitle": {
      "sentCashTransfer": "Transfer cancelled",
      "receivedCashTransfer": "Transfer sent to your account"
    }
  },
  "settings": {
    "title": "Settings",
    "changeLanguageTitle": "Change language",
    "changeLanguageDescription": "Change the language used by the app",
    "languageChangeConfirmation": "The application's language has been changed successfully",
    "languageChangeFailed": "An error occured while loading language",
    "changePincodeTitle": "Change my secret code",
    "changePincodeDescription": "For more security, change your pincode regularly",
    "accountDeleteTitle": "Delete my account",
    "accountDeleteDescription": "Delete my Skaleet account permanently."
  },
  "accountDeleteScreen": {
    "title": "We will miss you!",
    "subtitle": "Note that you do not need to delete your account if:",
    "button": "Delete my account",
    "explanation": "Your request will be sent to our team. This operation may take several days.",
    "options": {
      "changeProfile": {
        "title": "You want to change your pincode",
        "description": "Update your pincode in the settings."
      },
      "unHappy": {
        "title": "You are not happy with us",
        "description": "Contact us and tell us."
      }
    },
    "confirm": {
      "title": "Do you really want to request the closure of your account ?",
      "subtitle": "You will permanently lose all your account datas, contacts and profile info",
      "placeholder": "Please provide a reason for closing the account",
      "warning": "After this, there is no turning back",
      "button": "Confirm your request",
      "success": "Account closing request has been successfully sent"
    }
  },
  "accountDeleteMail": {
    "subject": "Request to delete the account of {username}",
    "body": "Dear Sir or Madam, I ask you to delete my user account."
  },
  "transactionFilter": {
    "all": "All",
    "credit": "Income",
    "debit": "Outcome",
    "searchDescription": "Easily find your transactions thanks to the search engine.",
    "searchInputPlaceholder": "Search",
    "timeRangeSelectionTitle": "Custom date",
    "timeRange": {
      "month": "This month",
      "week": "Last 7 days",
      "quarter": "Last 3 months",
      "from": "From:",
      "to": "To:"
    },
    "advancedSearch": "Advanced Search",
    "confirm": "Confirm",
    "amountRangeSelectionTitle": "Filter by amount",
    "allAmountRange": "All amounts",
    "amountRange": {
      "min": "Min:",
      "max": "Max:"
    },
    "emptySearchResult": "No transaction"
  },
  "resultScreen": {
    "activateCard": {
      "successTitle": "Congratulations !",
      "successSubtitle": "Your card has been activated !",
      "failureTitle": "Error",
      "failureSubtitle": "Please excuse us.",
      "failureDescription": "It would seem that an error occurred while activating your card. Please try again later or contact our customer service."
    },
    "createCard": {
      "successTitle": "Congratulations !",
      "defaultSuccessSubtitle": "Your card has been created !",
      "failureTitle": "Error",
      "failureSubtitle": "Please excuse us.",
      "failureDescription": "An error occurred while creating your card. Please try again later or contact our customer service."
    },
    "updateOutstanding": {
      "successTitle": "Congratulations !",
      "defaultSuccessSubtitle": "Your limit has been updated !",
      "failureTitle": "Error",
      "failureSubtitle": "Please excuse us.",
      "failureDescription": "An error occurred while updating your card limit. Please try again later or contact our customer service."
    },
    "refabricate": {
      "successTitle": "Congratulations !",
      "successSubtitle": "Your demand has been taken into account !",
      "failureTitle": "Error",
      "failureSubtitle": "Please excuse us.",
      "failureDescription": "An error occurred while replacing your card. Please try again later or contact our customer service."
    },
    "sensitiveDetails": {
      "successTitle": "Congratulations !",
      "successSubtitle": "Continue to consult the card sensitive data",
      "failureTitle": "Error",
      "failureSubtitle": "Please excuse us.",
      "failureDescription": "An error occurred while getting your card's sensitive data. Please try again later or contact our customer service."
    },
    "done": "Done"
  },
  "createCard": {
    "title": "New card",
    "virtualCardtitle": "New virtual card",
    "loadProductsError": "An error occurred.",
    "loadProductsRetry": "Retry",
    "stepSelection": {
      "title": "Account selection",
      "selectAccountMessage": "Please select the account for which you wish to order a card:",
      "selectVirtualCardMessage": "Please select the account for which you wish to create a virtual card:",
      "selecProductMessage": "Which card would you like to order ?",
      "selectProductForVirtualCardMessage": "Which virtual card would you like to create ?",
      "orderCardButton": "Order my card",
      "createVirtualCardButton": "Create a virtual card",
      "virtualCardTitle": "Get a new virtual card instantly",
      "virtualCardDescription": "For secure online purchases without fear of losing your card!",
      "accountLabel": "Account n°{id}"
    },
    "stepCgu": {
      "title": "Terms of Service",
      "acceptMessage": "By validating the switch, you confirm that you have read and accepted our Terms of Service",
      "next": "Next"
    }
  },
  "recharge": {
    "title": "Choose an account",
    "description": "Select an account to recharge",
    "noAccountAvailable": "No account available",
    "noAccountExplanation": "It looks like you don't have a reloadable account.",
    "contactSupport": "Contact support",
    "rechargeButton": "Recharge"
  },
  "rechargeMethod": {
    "title": "Recharge methods",
    "subtitle": "How do you want to recharge your account?",
    "withCard": {
      "title": "Bank card",
      "description": "Your account will be credited instantly. Withdrawals at partner banks all over the world.",
      "instantTag": "Instantly"
    },
    "bankDebit": {
      "title": "Direct bank debit",
      "description": "Fund the account from your external account {bankName}. Your account will not be credited instantly.",
      "noBankDescription": "Fund the account from your external account. Your account will not be credited instantly."
    }
  },
  "contactSupport": {
    "rechargeNotAvailableTitle": "Can't recharge your account ?",
    "rechargeNotAvailableMessage": "Please contact our support team who will tell you what to do.",
    "contactByPhone": "Contact us by phone at {phoneNumber}",
    "contactByEmail": "Contact us by email at {email}",
    "done": "Done"
  },
  "rechargeBankToWallet": {
    "title": "Recharge by direct debit",
    "subtitle": "Amount to recharge",
    "accountWithdrawnTitle": "Debit external account",
    "accountWithdrawnMessage": "Editable via the Transfers menu",
    "iban": "IBAN",
    "amountToWithdraw": "Amount to be withdrawn :",
    "recharge": "Recharge"
  },
  "rechargeByCard": {
    "title": "Recharge by card",
    "subtitle": "Amount to recharge",
    "creditedAccountTitle": "Credited account",
    "accountId": "N°{id}",
    "amountToCredit": "Amount to be credited :",
    "recharge": "Recharge"
  },
  "rechargeSummary": {
    "title": "Success",
    "rechargedAccount": "Recharged account",
    "rechargeMethod": "Recharge method",
    "bankToWalletMethod": "Direct debit",
    "cardMethod": "By card",
    "rechargeAmount": "Recharged amount",
    "done": "Finish"
  },
  "recurringTransfer": {
    "endDateLabel": "End date",
    "firstExecutionDateLabel": "Start date",
    "frequencyLabel": "Frequency",
    "switchLabel": "Make this a recurring transfer",
    "optionLabel": {
      "daily": "Daily",
      "weekly": "Weekly",
      "twiceAMonth": "Twice a month",
      "monthly": "Monthly"
    },
    "recurringTransferTitleDetails": "To {destinationAccount}",
    "beneficiaryDetails": "Beneficiary details",
    "scheduledAmount": "Scheduled amount",
    "beneficiaryAccount": "Beneficiary account",
    "beneficiaryBIC": "Beneficiary BIC",
    "recurringTransferDetails": "Recurring transfer details",
    "sourceAccount": "Source account",
    "frequency": "Frequency",
    "nextExecutionDate": "Next execution date",
    "endDate": "End date",
    "description": "Description"
  },
  "transactionCode": {
    "title": "Transaction code",
    "description": "Create a code to use it in one of our points of transaction.",
    "noCodeAvailable": "No active transaction code",
    "noCodeAvailableExplanation": "To generate a new transaction code, obtain the partner's 6-digit number.",
    "codeTooltip": "Code",
    "newCodeButton": "New code",
    "modalBackButton": "Back",
    "selectAcceptor": {
      "title": "Transaction point",
      "description": "Enter the 6-digit number of the transaction point",
      "next": "Next"
    },
    "selectAmount": {
      "title": "Allowed amount",
      "acceptorReminder": "Transaction point : {acceptorId}",
      "currencyLabel": "Currency :",
      "amountLabel": "Amount :",
      "amountInformationLabel": "Maximum transaction amount (including fees)",
      "next": "Generate my code"
    },
    "pincode": {
      "title": "Secret code",
      "description": "Enter your secret code to finish"
    },
    "summary": {
      "title": "Success",
      "transactionCode": "Transaction code",
      "transactionPoint": "Transaction point",
      "maxAmount": "Max. amount",
      "expiryDate": "Expiry date",
      "done": "Done"
    }
  },
  "trustChoice": {
    "title": "Trust this browser ?",
    "explanation": "If you choose to trust this browser, you will not have to enter a validation code during your next connection.",
    "laterButton": "Not now",
    "dontTrustButton": "Do not trust",
    "trustButton": "Trust"
  },
  "ubbleCheckScreen": {
    "success": {
      "title": "WELL DONE!",
      "subtitle": "Thank you for your submission!",
      "message": "Your identity is being verified on our end. Verification process can take from a few hours up to a few days.\nYou can now log in your space or close this window.",
      "button": "Continue to login"
    },
    "fail": {
      "title": "Oops!",
      "subtitle": "Something went wrong during the submission process...",
      "message": "You can try again using the same link as before.\nIf you experience an other error, please take contact with our team by phone or e-mail using the buttons below.",
      "byPhone": "Phone",
      "byEmail": "E-mail"
    }
  },
  "justifyScreen": {
    "title": "Justify your expenses",
    "subtitle": "Add or modify your justifications",
    "done": "Done",
    "attachments": {
      "title": "Attachments",
      "sectionTitle": "Upload your attachments",
      "sectionSubtitle": "Format pdf, jpeg, png",
      "addButtonLabel": "Add an attachment",
      "addOtherButtonLabel": "Add another attachment",
      "deletePrompt": {
        "title": "Supprimer le justificatif",
        "message": "Êtes-vous sûr de vouloir supprimer ce justificatif ?",
        "cancelButton": "Annuler",
        "confirmButton": "Supprimer"
      }
    },
    "spendings": {
      "title": "Spendings",
      "sectionTitle": "Add a spending",
      "sectionSubtitle": "Declare your spendings",
      "addButtonLabel": "Add a spending",
      "addOtherButtonLabel": "Add another spending",
      "addModal": {
        "title": "Add a spending",
        "editTitle": "Edit a spending",
        "message": "Additional details of the justification",
        "createButton": "Add",
        "editButton": "Edit"
      },
      "addForm": {
        "titleLabel": "Title",
        "categoryLabel": "Category",
        "subcategoryLabel": "Subcategory",
        "amountLabel": "Amount",
        "additionalDataLabel": "Additional data"
      },
      "deletePrompt": {
        "title": "Supprimer le justificatif",
        "message": "Êtes-vous sûr de vouloir supprimer ce justificatif ?",
        "cancelButton": "Annuler",
        "confirmButton": "Supprimer"
      }
    }
  },
  "selectField": {
    "select": "Select",
    "select_an_option": "Select an option"
  },
  "refundTransactionModal": {
    "title": "Ask for a refund",
    "message": "To ask for a refund, please select a reason below:",
    "reasons": {
      "AC03": "Wrong IBAN",
      "AM09": "Wrong montant",
      "CUST": "Customer decision"
    },
    "confirmButton": "Confirm",
    "cancelButton": "Cancel",
    "success": "Your refund request has been sent"
  },
  "upcomingTransfers": {
    "description": "View and manage your upcoming transfers",
    "editTransfer": "Edit recurring transfer",
    "loadMore": "Load more",
    "nextOn": "Next on",
    "status": {
      "activated": "Active",
      "deactivated": "Paused",
      "expired": "Executed",
      "deleted": "Canceled"
    },
    "deletePrompt": {
      "title": "Delete Upcoming transfer?",
      "message": "Do you really want to delete Upcoming transfer {id} ?"
    },
    "confirmButton": "Confirm",
    "sourceAccount": "Source account",
    "scheduledAmount": "Scheduled amount",
    "message": "Message",
    "startDate": "Start date",
    "frequency": "Frequency",
    "endDate": "End date"
  },
  "createSavingsAccount": {
    "title": "Open a savings account",
    "selectAccount": "Select savings account type",
    "initialDeposit": "Initial deposit",
    "accountToCollect": "Account to collect",
    "duration": "Duration",
    "sendRequest": "Send request",
    "selectAccountTypeLabel": "Select the type of the account to collect",
    "selectAccountType": {
      "INTERNAL": "Internal",
      "EXTERNAL": "External"
    },
    "creationSuccessMessage": "Your request to open a savings account has been successfully sent"
  }
}
