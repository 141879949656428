import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { authenticationManager, i18NManager, savingsManager } from "../../../../../shared/core/service/services";
import { AccountType } from "../../../../../shared/domains/account/account";
import { ACC_TYPE_IE_OPTIONS, ACCOUNT_TYPES_IE, DURATION_CYCLE } from "../../../../../shared/domains/savings/constants";
import type { AccountTypeIE, SavingContract, SavingProduct } from "../../../../../shared/domains/savings/savings";
import type { AccountOrRecipient } from "../../../../../shared/domains/transactions/customer-instruction";
import { useObservable } from "../../../../../shared/utils/observable";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { AmountInput } from "../../../common/forms/amount-input";
import FormLabel from "../../../common/forms/form-label";
import { SelectInput } from "../../../common/forms/select-input";
import { TextInput } from "../../../common/forms/text-input";
import { ChevronIcon } from "../../../common/svg/chevron";
import { theme } from "../../../styles/theme";
import {
  AccountButton,
  SourceAndDestination,
  SourceAndDestinationEnum,
} from "../../transfer/components/source-and-destination";

interface CreateSavingModalProps {
  handleSendRequest: (contract: SavingContract) => void;
}

export const CreateSavingModal = ({ handleSendRequest }: CreateSavingModalProps) => {
  const { formatMessage } = useIntl();
  const allSavingsProduct = useObservable(savingsManager.allSavingsProduct);
  const selectedLang = useObservable(i18NManager.localeTag);

  const session = authenticationManager.session.get();

  /** Form values and handlers */
  const [savingsAccountType, setSavingsAccountType] = useState<SavingProduct>();
  const [amount, setAmount] = useState({ value: 0, currency: "EUR" });
  const [typeOfAccount, setTypeOfAccount] = useState<AccountTypeIE>(ACCOUNT_TYPES_IE.INTERNAL);
  const [duration, setDuration] = useState({ value: "", type: DURATION_CYCLE[0] });
  const [accountToCollect, setAccountToCollect] = useState<AccountOrRecipient | null>(null);
  const [showAccounts, setShowAccounts] = useState(false);

  useEffect(() => {
    if (savingsAccountType) {
      setAmount({ ...amount, currency: savingsAccountType.currency });
      setDuration({ ...duration, type: savingsAccountType.duration.cycleFrequency });
    }
  }, [savingsAccountType]);

  useEffect(() => {
    if (typeOfAccount === ACCOUNT_TYPES_IE.EXTERNAL) {
      setAccountToCollect(null);
    }
  }, [typeOfAccount]);

  const toggleAccountList = () => {
    setShowAccounts((prevVal) => !prevVal);
  };

  const onSendRequest = () => {
    if (canSubmit) {
      const contract: SavingContract = {
        accountToCollectType: typeOfAccount,
        clientId: session.clientId!,
        duration: { cycleFrequency: duration.type, value: parseInt(duration.value) },
        isTaxable: false,
        productCode: savingsAccountType.code,
        productType: savingsAccountType.type,
        taxProfileIds: [],
      };

      if (typeOfAccount === ACCOUNT_TYPES_IE.INTERNAL && accountToCollect) {
        contract.clientCurrentAccountNumber = accountToCollect.id;
        contract.initialAmount = { amount: amount.value, currency: amount.currency };
      } else if (typeOfAccount === ACCOUNT_TYPES_IE.EXTERNAL) {
        contract.minDepositAmount = { amount: amount.value, currency: amount.currency };
      }

      handleSendRequest(contract);
    }
  };

  const canSubmit =
    savingsAccountType &&
    (typeOfAccount === ACCOUNT_TYPES_IE.INTERNAL ? accountToCollect : true) &&
    amount.value > 0 &&
    duration.value &&
    duration.type;

  return (
    <Container>
      <LeftColumn>
        <FormLabel size="normal" margin="normal" label={"createSavingsAccount.selectAccount"} />
        <SelectInput
          style={{ width: "100%" }}
          innerId="savings-account-type"
          options={allSavingsProduct.items}
          value={savingsAccountType}
          onChange={setSavingsAccountType}
          itemRenderer={(v) => v?.names?.[selectedLang] ?? v?.code}
        />

        <FormLabel size="normal" margin="normal" label={"createSavingsAccount.initialDeposit"} />
        <AmountInput id="initial-deposit-amount" variant="big" value={amount} onChange={setAmount} />

        <FormLabel size="normal" margin="normal" label={"createSavingsAccount.selectAccountTypeLabel"} />
        <SelectInput
          style={{ width: "100%" }}
          innerId="type-of-account"
          options={ACC_TYPE_IE_OPTIONS}
          value={typeOfAccount}
          onChange={setTypeOfAccount}
          itemRenderer={(v) => formatMessage(`createSavingsAccount.selectAccountType.${v}`)}
        />

        {typeOfAccount === ACCOUNT_TYPES_IE.INTERNAL && (
          <>
            <FormLabel size="normal" margin="normal" label={"createSavingsAccount.accountToCollect"} />
            {accountToCollect ? (
              <AccountButton account={accountToCollect} onClick={toggleAccountList} />
            ) : (
              <SourceAndDestinationButton type="button" active={showAccounts} onClick={toggleAccountList}>
                {formatMessage("amountSelection.choose_source_account")}
                <ChevronIcon width={18} height={18} />
              </SourceAndDestinationButton>
            )}
          </>
        )}

        <FormLabel size="normal" margin="normal" label={"createSavingsAccount.duration"} />
        <TextInput
          id="duration-value"
          value={duration.value}
          onChange={(event) => setDuration({ ...duration, value: event.target.value })}
          type="number"
          min={1}
          onWheel={(e) => {
            if (e.target instanceof HTMLElement) {
              e.target.blur();
            }
          }}
        />

        <SelectInput
          style={{ width: "100%", marginTop: 8 }}
          innerId="duration-type"
          options={DURATION_CYCLE}
          value={duration.type}
          onChange={(value) => setDuration({ ...duration, type: value })}
          itemRenderer={(v) => formatMessage(`accountDetails.monthsLabels.${v}`)}
          disabled
        />

        <ButtonContainer>
          <PrimaryButton size="S" onClick={onSendRequest} disabled={!canSubmit}>
            {formatMessage("createSavingsAccount.sendRequest")}
          </PrimaryButton>
        </ButtonContainer>
      </LeftColumn>

      <RightColumn>
        <SourceAndDestination
          type={showAccounts ? SourceAndDestinationEnum.Source : null}
          sourceAccount={accountToCollect}
          accountTypeFilter={AccountType.Current}
          onSourceAccountChange={(acc) => {
            setAccountToCollect(acc as AccountOrRecipient);
            setShowAccounts(false);
          }}
        />
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
`;

const LeftColumn = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const SourceAndDestinationButton = styled("button")<{
  disabled?: boolean;
  active: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  appearance: none;
  border: none;
  height: 48px;
  box-shadow: none;
  background-color: white;
  text-align: left;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  ${theme.bodyBlackRegular};
  ${(props) =>
    props.active &&
    css`
      color: ${theme.mainColor()};
      svg {
        fill: ${theme.mainColor()};
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      :hover {
        cursor: not-allowed;
      }
    `}
`;
